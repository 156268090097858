import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import OrderList from './components/OrderList';
import OrderDetail from './components/OrderDetail';
import PrivateRoute from './components/PrivateRoute';
import './styles.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/orders" element={
          <PrivateRoute>
            <OrderList />
          </PrivateRoute>
        } />
        <Route path="/order/:orderId" element={
          <PrivateRoute>
            <OrderDetail />
          </PrivateRoute>
        } />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;